import Vue from 'vue'
import { withQuery, auth, authData, client } from '@maxsystems/client'

import getAppraisal from './queries/getAppraisal.gql'
import getACVMaxRetail from './queries/getACVMaxRetail.gql'
import getGuarantee from './queries/getGuarantee.gql'
import updateAppraisal from './queries/updateAppraisal.gql'

export default new Vue({
  data: () => ({
    authData,
    actions: {
      fetchAppraisals: withQuery(getAppraisal),
      fetchGuarantee: withQuery(getGuarantee),
      fetchRetail: withQuery(getACVMaxRetail),
      updateAppraisal: withQuery(updateAppraisal)
    },
    appraisalId: null,
    appraisal: null,
    guaranteeValue: undefined
  }),

  computed: {
    amount: ({ appraisal }) => appraisal?.offer?.value?.amount,
    guarantee: vm => ({
      isEnabled: vm.appraisal?.dealer.showACVGuarantee,
      isLoading: vm.actions.fetchGuarantee.isPending(),
      value: vm.guaranteeValue
    }),
    isLoggedIn: ({ authData: { isLoggedIn } }) => isLoggedIn,
    profile: ({ appraisal }) => appraisal?.customer?.profile,
    fetchingAppraisal: ({ actions }) => actions.fetchAppraisals.isPending()
  },

  watch: {
    appraisalId (value) {
      this.load(value)
    }
  },

  methods: {
    async isAuthenticated () {
      if (this.isLoggedIn) return true

      Vue.use(auth)
      auth.setup()

      return new Promise(resolve => {
        this.$watch('isLoggedIn', isLoggedIn => {
          if (!isLoggedIn) return
          resolve(true)
        }, { immediate: true })
      })
    },

    setEnv (env) {
      if (env) client.config({ env })
    },

    setAppraisalId (id) {
      this.appraisalId = id
    },

    async fetchGuarantee () {
      // Errors are handled by presenting "Not Available" ui state
      const { data } = await this.actions.fetchGuarantee.fetch({ appraisalId: this.appraisalId })
      this.guaranteeValue = data.appraisal.guarantee?.adjusted.amount || null
    },

    async fetchRetail () {
      const { appraisalId } = this

      // wait for appraisal to be loaded
      await Promise.all(Object.values(this.actions.fetchAppraisals.pending).map(({ req }) => req))

      if (!this.appraisal) return

      const { data } = await this.actions.fetchRetail.fetch({ appraisalId })
      this.$set(this.appraisal, 'acvMaxRetailValue', data.appraisal.acvMaxRetailValue)
    },

    async load (appraisalId) {
      this.appraisal = null

      try {
        const { data } = await this.actions.fetchAppraisals.fetch({ appraisalId })
        this.appraisal = data?.appraisal || null
      } catch {} // errors are handled by presenting "Not Available" ui state

      if (this.guarantee.isEnabled) this.fetchGuarantee()
    },

    async updateAppraisalOffer (offerInputs) {
      // make sure we are authenticated
      await this.isAuthenticated()

      const input = { updateOffer: offerInputs }
      const { data, errors } = await this.actions.updateAppraisal.fetch({ input })

      if (errors?.length) throw new Error(errors[0].message || errors[0].extensions.code)

      // update local cache
      this.appraisal.offer.value = data.updateAppraisal[0].appraisal.offer.value
    }
  }
})
